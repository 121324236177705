import React, { useReducer,  useEffect }  from 'react';

import './bridge-connect.css';
import Button from '../button/button'
import logo from '../../assets/images/hew.svg';
import axios from 'axios';

function reducer( currentState, newState ){
    return {...currentState, ...newState };
}

function BridgeConnect() {

    const [{currentlySearching, availableHueBridges, isError}, setState ] = useReducer( reducer, {
        currentlySearching: false,
        availableHueBridges: [],
        isError: false
    });

    let theSearch = null;
    if( currentlySearching ){
        theSearch = (
            <p>Searching for hue hubs</p>
        )
    }

     
    useEffect(() => {

        const fetchData = async () => {

            setState({
                currentlySearching: true,
                isError: false
            });

            try {
                const result = await axios('https://discovery.meethue.com/');

                const bridges = result.data.map( bridge => (
                    {
                        'id': bridge.id,
                        'address': bridge.internalipaddress
                    }
                ));

                setState({ 
                    availableHueBridges: bridges,
                    currentlySearching: false
                });

            } catch (error) {
                setState({
                    isError: true,
                    currentlySearching: false
                });
            }

        };

        if( currentlySearching === true ){
            fetchData();
        }

    }, [currentlySearching]);

    function beginHueSearch( e ){
        setState({
            currentlySearching: true
        });
    }
    console.log( isError );

    return (
        <div className="bridge-connect-wrap">

            <div className="bridge-content-wrap">
            
                <img src={logo} className="hew-logo" alt="logo" />

                <h1>Connect to your Hue Bridge</h1>

                <p>Before you connect to a new bridge make sure that you've already set everything up in the official Philips Hue app.</p>

                {availableHueBridges.length > 0 && 
                    <ul className="bridge-list">
                        { availableHueBridges.map( bridge => (
                            <li key={ bridge.id } className="bridge-list-item">
                                { bridge.address }<br />
                                { bridge.id }
                            </li>
                        ))}
                    </ul>
                }

                { theSearch }

                { isError === true && 
                    <p>There was an error retrieving your bridge infomration.</p>
                }

                <Button 
                    text="Connect New Bridge" 
                    onClick={ beginHueSearch }
                />

            </div>
            
        </div>
    );
}

export default BridgeConnect;
