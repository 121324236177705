import React from 'react';
import './button.css';

function Button(props) {

	// Declare a new state variable, which we'll call "count"  


    let classNames = [
        'btn'
    ];

    return (
        <button class={ classNames.join(' ') } onClick={props.onClick}>
            { props.text }
        </button>
    );
}

export default Button